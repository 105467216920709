import React from "react";

import { DateTime } from "luxon";
import { AutoSizer, Column, Table, defaultTableRowRenderer } from "react-virtualized";
import TableCell from "@material-ui/core/TableCell";

import useStyles from "./styles";
import { useEmails, useEmailsLoading } from "./state";
import { Loading } from "../components";
import { useCurrentWsDateFormat } from "../firebase";
import { Typography } from "@material-ui/core";

const Emails: React.FC = () => {
  const tableRef = React.createRef<Table>();
  const classes = useStyles();
  const data = useEmails();
  const loading = useEmailsLoading();
  const dateFormat = useCurrentWsDateFormat();
  const [shownEmails, setShownEmails] = React.useState<number[]>([]);

  React.useEffect(() => {
    tableRef.current?.recomputeRowHeights();
    tableRef.current?.forceUpdateGrid();
  }, [shownEmails, tableRef]);

  return (
    <>
      <div className={classes.title}>
        <Typography variant="h5">Emails audit</Typography>
        <Typography variant="subtitle2">Last 500 emails sent in this workspace</Typography>
      </div>
      <div className={classes.wrapper} style={{ paddingTop: loading ? 16 : undefined }}>
        {loading ? (
          <Loading />
        ) : data ? (
          <AutoSizer>
            {({ height, width }) => (
              <Table
                ref={tableRef}
                height={height}
                width={width}
                rowHeight={({ index }) => (shownEmails.includes(index) ? 248 : 48)}
                headerHeight={48}
                rowCount={data.length}
                onRowClick={({ index }) => {
                  const nextArray = [...shownEmails];
                  const shownIndex = nextArray.indexOf(index);
                  if (shownIndex !== -1) {
                    nextArray.splice(shownIndex, 1);
                  } else {
                    nextArray.push(index);
                  }
                  setShownEmails(nextArray);
                }}
                rowGetter={({ index }) => data[index]}
                className={`emails-table ${classes.table ?? ""}`}
                rowRenderer={(props) => {
                  const { index, style } = props;
                  if (shownEmails.includes(index))
                    return (
                      <div style={style} className={classes.messageRowWrapper}>
                        {defaultTableRowRenderer({
                          ...props,
                          style: {
                            width: style.width,
                            paddingRight: style.paddingRight,
                            height: 48,
                          },
                        })}
                        <div
                          // the data are sanitized in CF with dompurify utility
                          dangerouslySetInnerHTML={{ __html: data[index].message }}
                          className={classes.messageContainer}
                        />
                      </div>
                    );
                  return defaultTableRowRenderer(props);
                }}
              >
                {[
                  {
                    label: "Address",
                    dataKey: "to",
                    width: width * 0.3,
                  },
                  {
                    label: "Sent",
                    dataKey: "sent",
                    width: width * 0.15,
                  },
                  {
                    label: "Subject",
                    dataKey: "subject",
                    width: width * 0.4,
                  },
                  {
                    label: "Status",
                    dataKey: "status",
                    width: width * 0.15,
                  },
                ].map(({ dataKey, label, width }) => (
                  <Column
                    width={width}
                    key={dataKey}
                    headerRenderer={({ label }) => (
                      <TableCell className={classes.flexContainer}>
                        <b>{label}</b>
                      </TableCell>
                    )}
                    cellRenderer={({ cellData }) => (
                      <TableCell className={classes.flexContainer}>
                        {dataKey === "sent" && cellData?._seconds
                          ? DateTime.fromSeconds(cellData._seconds).toFormat(
                              `${dateFormat} HH:mm`,
                            )
                          : cellData}
                      </TableCell>
                    )}
                    dataKey={dataKey}
                    label={label}
                  />
                ))}
              </Table>
            )}
          </AutoSizer>
        ) : (
          "None"
        )}
      </div>
    </>
  );
};

export default Emails;
